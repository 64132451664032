/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useOutsideClick from "../clickOutSide";

import { useSidebarContext } from "../../Contexts/SidebarContext";
import Logo from "../../images/logo-dark.png";
import Logosm from "../../images/logo-sm.png";
import { searchTree } from "../../utils/constant/dashboardPages";
//switch user dialogue
import SwitchOrganization from "./switchuserdialog";
import ChangePassword from "./changepassworddialog";
import BreadCrumps from "./BreadCrumps";
import ImageLoader from "../parkinglotComponent/ImageLoader";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { showToast } from "../../utils/tools";
import ProfileDialog from "./profiledialog";
import { getMessageText, setLanguage } from "../../utils/message";
import * as LangConst from "../../utils/constant/LangConst";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
import { reDirectURLTo } from "../../utils/redirectionUtil";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedInternalOrg,
  setUserKey,
  setUserName,
} from "../../reducers/organizationSlice";

let orgFirstKey = "";
let orgName = "";

export default function Navbar() {
  const { openSidebar, sideBar, user, setCountryCodes, selectedLanguage } =
    useSidebarContext();
  const location = useLocation();
  useEffect(() => {}, [selectedLanguage]);
  useEffect(() => {
    LocalStorageUtil.setItem("finparkLastViewedPage", location?.pathname);
    loadServerandKeys();
  }, [location?.pathname]);

  const loadServerandKeys = async () => {
    let mili = new Date().getTime();
    const res = await axios.get(`../server.json?rand=${mili}`);
    try {
      setCountryCodes(
        res.data.servers.server.USPassCode,
        res.data.servers.server.CanadaPassCode
      );
    } catch (e) {
      console.log(e);
    }
  };

  if (
    location.pathname !== "/finparkRegistration" &&
    location.pathname !== "/globaldashboard" &&
    location.pathname !== "/SignUpPage"
  )
    return (
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/mapdashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src="../../assets/images/logo-sm.png"
                    alt="logo-sm"
                    height="36"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="../../assets/images/logo-dark.png"
                    alt="logo-dark"
                    height="36"
                  />
                </span>
              </Link>
            </div>
            <button
              style={{ color: "black" }}
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item"
              id="vertical-menu-btn"
              onClick={(e) => openSidebar()}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>
            <div className="ms-0 head-page-title header-item font-size-12 d-none d-sm-none d-md-block d-lg-block d-xl-block ms-xl-4">
              <div className="page-title-right">
                <BreadCrumps />
              </div>
            </div>
          </div>
          <div className="topnav-right">
            <UserSettings locations={location?.pathname} />
          </div>
        </div>
        {/* <SideDrawer /> */}
      </header>
    );
  return <></>;
}

export const OrgImage = React.memo((props: any) => {
  const dispatch = useDispatch();
  const [imageURL, setImageURL] = useState("");
  const api = useAxiosPrivate();
  const { user, imageurl, changeOrgType, setisOrgNetworkConfigEnabled } =
    useSidebarContext();
  const getOrganizationDetails = async () => {
    const saasOrgId: any = LocalStorageUtil.getItem("saasOrgId") || "";
    try {
      const { data } = await api.get(
        `/api/organization/${JSON.parse(saasOrgId)}`
      );
      changeOrgType(data?.usageType);
      setisOrgNetworkConfigEnabled(data.isOrgNetworkConfigEnabled);
      LocalStorageUtil.setItem("orgStatus", data.status);
      const newKey: any = window.sessionStorage.getItem("user");
      const localItems: any = localStorage.getItem(newKey);
      const localData: any = JSON.parse(localItems);
      const pageUpdate = {
        ...localData,
        orgStatus: data.status,
      };
      window.sessionStorage.setItem("local", JSON.stringify(pageUpdate));
      let selectedInternalOrg = LocalStorageUtil.getItem("saasOrgId");
      try {
        selectedInternalOrg =
          JSON.parse(LocalStorageUtil.getItem("selectedInternalOrg")) ||
          JSON.parse(LocalStorageUtil.getItem("saasOrgId"));
      } catch (e) {
        selectedInternalOrg = LocalStorageUtil.getItem("saasOrgId");
      }
      dispatch(setSelectedInternalOrg(selectedInternalOrg));
      setImageURL(data.logoPath);
    } catch (error) {
      showToast(
        "ERROR",
        getMessageText(LangConst.ERROR_RETRIEVING_DATA, "Error retrieving data")
      );
    }
  };

  useEffect(() => {
    getOrganizationDetails();
  }, [user, imageurl]);

  if (props?.noImage && !imageURL) return <> </>;

  return (
    <ImageLoader
      styleObject={props?.styleObject ? props?.styleObject : {}}
      path={imageURL}
      className={
        props?.className ? props?.className : "rounded-3 header-profile-user"
      }
      notFoundImg="./../assets/images/noProfile.png"
    />
  );
});

const UserSettings = ({ locations }: any) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState("hide");
  const [openSwitch, setOpenSwitch] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [languages, setLanguages] = React.useState([
    "English",
    "French",
    "Spanish",
  ]);
  const [isLangDropDrown, setIsLangDropDrown] = useState(false);
  const {
    username,
    userFirstName,
    userLastName,
    changeLanguage,
    selectedLanguage,
  } = useSidebarContext();
  const wrapper = useRef(null);
  const navigate = useNavigate();
  const saasOrgId: any = LocalStorageUtil.getItem("loggedInSaasOrgId") || "";

  useOutsideClick(wrapper, () => {
    if (open === "show") setOpen("hide");
    if (isLangDropDrown === true) setIsLangDropDrown(false);
  });

  useEffect(() => {}, [selectedLanguage]);

  const onSwitchUser = (e: any) => {
    e.preventDefault();
    setOpenSwitch(true);
  };

  const onChangePassword = () => {
    setOpenChangePassword(true);
  };

  const onClickProfile = () => {
    setOpenProfile(true);
  };

  const onHandleClose = (e: any) => {
    setOpenChangePassword(false);
    setOpenSwitch(false);
    setOpenProfile(false);
  };

  // useEffect(() => {
  //   updateLocalData();
  // }, [locations]);

  useEffect(() => {
    document.removeEventListener("visibilitychange", handleActivityFalse);
    document.addEventListener("visibilitychange", handleActivityFalse);
    return () => {
      document.removeEventListener("visibilitychange", handleActivityFalse);
    };
  }, []);

  const updateLocalData = () => {
    const newKey: any = window.sessionStorage.getItem("user");
    const localData: any = window.sessionStorage.getItem("local");
    if (localData) {
      const update = JSON.parse(localData);
      const pageUpdate = {
        ...update,
        finparkLastViewedPage: locations ? locations : "/mapDashboard",
      };
      localStorage.setItem("lastLoginKey", newKey);
      window.sessionStorage.setItem("local", JSON.stringify(pageUpdate));
    }
  };

  const handleActivityFalse = () => {
    if (!document.hidden) {
      updateLocalData();
    } else {
      const newKey: any = window.sessionStorage.getItem("user");
    }
  };

  // const loadLanguages = async (lang: string) => {
  //   let langUrl = "../language.json";

  //   if (lang === "French") {
  //     langUrl = "../french.json";
  //   } else if (lang === "Spanish") {
  //     langUrl = "../spanish.json";
  //   }

  //   try {
  //     const res = await axios.get(langUrl);
  //     setLanguage(res.data);
  //     setSelectedLang(lang);
  //   } catch (error) {
  //     console.error("Error loading language data:", error);
  //   }
  // };

  const handleLanguageChange = (lang: string) => {
    const newLanguages = languages.filter((language) => language !== lang);
    newLanguages.push(lang);
    changeLanguage(lang);
    setIsLangDropDrown(false);
  };

  return (
    <div className="dropdown d-inline-block user-dropdown" ref={wrapper}>
      <button
        type="button"
        className="btn user-dp header-item d-flex"
        id="page-header-user-dropdown"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(e: any) => {
          setOpen("show");
        }}
      >
        <OrgImage />
        <div className="d-flex flex-column">
          <div className="d-inline-block">
            <span className="d-none d-xl-inline-block ms-1 font-size-12">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </div>
          <div>
            <div
              className="username d-none d-xl-block ms-1 font-size-9"
              style={{
                lineHeight: "0.6rem",
                textAlign: "left",
                overflow: "hidden",
                height: "20px",
                top: "30px",
              }}
            >
              {`${userFirstName} ${userLastName}`}
            </div>
          </div>
        </div>
      </button>

      <div
        className={`dropdown-menu dropdown-menu-end ${open}`}
        style={{ cursor: "pointer" }}
      >
        <a className="dropdown-item" onClick={onClickProfile}>
          <i className="ri-user-line align-middle me-1"></i>
          {getMessageText(LangConst.PROFILE, "Profile  ")}
        </a>
        {/*<div className="dropdown-divider"></div>

         <a
          className="dropdown-item"
          style={{ paddingLeft: "2px" }}
          onClick={() => setIsLangDropDrown(!isLangDropDrown)}
        >
          {!isLangDropDrown ? (
            <i className="ri-arrow-down-s-fill align-middle mx-1"></i>
          ) : (
            <i className="ri-arrow-left-s-fill align-middle mx-1"></i>
          )}
          <i className="ri-translate align-middle me-2"></i>
          {selectedLanguage}
        </a> */}
        {isLangDropDrown && (
          <div
            style={{
              position: "absolute",
              right: "100%",
              top: "23%",
              backgroundColor: "#fff",
              zIndex: 9999999,
              boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            }}
          >
            {languages.map((lang) => (
              <a
                key={lang}
                className="dropdown-item"
                onClick={() => handleLanguageChange(lang)}
              >
                {lang}
              </a>
            ))}
          </div>
        )}

        <div className="dropdown-divider"></div>
        <a className="dropdown-item" onClick={onChangePassword}>
          <i className="ri-lock-line align-middle me-1"></i>
          {getMessageText(LangConst.CHANGE_PASSWORD, "Change Password ")}
        </a>

        {saasOrgId === "1" ? (
          <>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" onClick={(e) => onSwitchUser(e)}>
              <i className="ri-user-line align-middle me-1"></i>
              {getMessageText(
                LangConst.SWITCH_ORGANIZATION,
                "Switch Organization "
              )}
            </a>
          </>
        ) : null}
        <div className="dropdown-divider"></div>
        <a
          className="dropdown-item text-danger"
          onClick={() => {
            LocalStorageUtil.setItem("finparkLastViewedPage", "");
            LocalStorageUtil.setItem("token", "");
            sessionStorage.clear();
            reDirectURLTo(navigate, "/");
            localStorage.removeItem("lastLoginKey");
          }}
        >
          <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
          {getMessageText(LangConst.LOGOUT, "Logout ")}
        </a>
      </div>
      <SwitchOrganization show={openSwitch} onCancelDialogue={onHandleClose} />
      <ChangePassword
        show={openChangePassword}
        onCancelDialogue={onHandleClose}
      />
      <ProfileDialog show={openProfile} onCancelDialogue={onHandleClose} />
    </div>
  );
};
