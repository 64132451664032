import React, {
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
} from "react";
import axios from "axios";
import { setLanguage } from "../utils/message";
import { LocalStorageUtil } from "../utils/LocalStorageUtil";

const SidebarContext = createContext();

export const SidebarContextPrv = ({ children }) => {
  const [sideBar, setSideBar] = useState(false);
  const [orgType, setOrgType] = useState(["FLEETORG"]);
  const [user, setUser] = useState(1);
  const [imageurl, setImageurl] = useState("");
  const [username, setUsername] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [breadCrumpsData, setbreadCrumpsData] = useState({});
  const [dialogueData, setDialogueData] = useState("");
  const [openDialogue, setOpenDialogue] = useState(false);
  const [USCODE, setUSCODE] = useState("");
  const [CADCODE, setCADCODE] = useState("");
  const [name, setName] = useState("");
  const [language, setLang] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [isOrgNetworkConfigEnabled, setisOrgNetworkConfigEnabled] =
    useState("");
  const openSidebar = () => {
    setSideBar(!sideBar);
  };
  const localLang = LocalStorageUtil.getItem("language");
  useEffect(() => {
    if (localLang) {
      setLang(JSON.parse(localLang));
      setSelectedLanguage(JSON.parse(localLang));
      loadLanguages(JSON.parse(localLang));
    }
  }, [selectedLanguage]);

  const loadLanguages = async (lang) => {
    let langUrl = "../language.json";
    if (lang === "French") {
      langUrl = "../french.json";
    } else if (lang === "Spanish") {
      langUrl = "../Spanish.json";
    }
    try {
      const res = await axios.get(langUrl);
      setLanguage(res?.data);
      setLang(res?.data);
      //setSelectedLanguage(lang);
    } catch (error) {
      console.error("Error loading language data:", error);
    }
  };

  const changeUser = (orgKey) => {
    setUser(orgKey);
  };

  const changeLanguage = (lang) => {
    loadLanguages(lang);
    setSelectedLanguage(lang);
    LocalStorageUtil.setItem("language", JSON.stringify(lang));
  };

  const setCountryCodes = (US, CAD) => {
    setUSCODE(US);
    setCADCODE(CAD);
  };

  const changeOrgType = (orgType) => {
    setOrgType(orgType);
  };

  const changeUserName = (name) => {
    setUsername(name);
  };

  const closeDialogueWindow = () => {
    setOpenDialogue(false);
    setDialogueData("");
  };
  const openDialogueWindow = (data) => {
    setOpenDialogue(true);
    setDialogueData(data);
  };

  const switchOrg = (value) => {
    setName(value);
  };

  const memoizedValue = useMemo(
    () => ({
      sideBar,
      openSidebar,
      user,
      changeUser,
      changeUserName,
      username,
      breadCrumpsData,
      imageurl,
      setImageurl,
      setbreadCrumpsData,
      closeDialogueWindow,
      openDialogueWindow,
      dialogueData,
      openDialogue,
      setUserFirstName,
      setUserLastName,
      userFirstName,
      userLastName,
      changeOrgType,
      orgType,
      USCODE,
      CADCODE,
      setCountryCodes,
      isOrgNetworkConfigEnabled,
      setisOrgNetworkConfigEnabled,
      name,
      switchOrg,
      language,
      selectedLanguage,
      changeLanguage,
    }),
    [
      sideBar,
      openSidebar,
      user,
      changeUser,
      changeUserName,
      username,
      breadCrumpsData,
      imageurl,
      setImageurl,
      setbreadCrumpsData,
      openDialogue,
      setUserFirstName,
      setUserLastName,
      userFirstName,
      userLastName,
      changeOrgType,
      orgType,
      USCODE,
      CADCODE,
      setCountryCodes,
      isOrgNetworkConfigEnabled,
      setisOrgNetworkConfigEnabled,
      name,
      switchOrg,
      selectedLanguage,
    ]
  );

  return (
    <SidebarContext.Provider value={memoizedValue}>
      {children}
    </SidebarContext.Provider>
  );
};
// make sure use
export const useSidebarContext = () => {
  return useContext(SidebarContext);
};
